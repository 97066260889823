








import Vue from "vue";
import Component from "vue-class-component";
import config from '../lib/config';

@Component
export default class extends Vue {
  appName = config.appName;
}
